import { http } from '@/http/axios.js'
// list
export function getListAPI(params) {
  return http({
    url: '/company/activity/hdkyy/list',
    method: 'get',
    params
  })
}
// 核销
export function chargesOffAPI(id) {
  return http({
    url: '/company/activity/hdkyy/pay',
    method: 'post',
    data:{id}
  })
}
